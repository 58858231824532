/* Container for all projects */
.projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px 0;
}

/* Individual project card */
.project-card {
    border: 1px solid #e1e4e8; /* Light gray border */
    border-radius: 8px; /* Rounded corners */
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    transition: transform 0.2s; /* Smooth hover effect */
    color: #ffffff;
}

.project-card:hover {
    transform: translateY(-5px); /* Slight lift on hover */
}

/* Project name header within card */
.project-card h2 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 1.5em;
    color: #ffffff;
}

/* Description within card */
.project-card p {
    margin-bottom: 15px;
    color: #ffffff;
}

/* Link to view project on GitHub */
.project-link {
    display: inline-block;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    background-color: #0366d6; /* GitHub blue */
    color: #ffffff; /* White text */
    text-decoration: none;
    font-weight: lighter;
    transition: background-color 0.2s; /* Smooth hover effect */
}

.project-link:hover {
    background-color: #0056b3; /* Darker blue on hover */
}