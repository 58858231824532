.about-me-section {
    font-family: Arial, sans-serif;
    padding: 20px;
    line-height: 1.6;
}

.profile-pic {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-right: 20px;
}

.intro {
    display: flex;
    align-items: center;
}

.experience,
.current-projects,
.aspirations,
.invitation {
    margin-top: 20px;
}

h3 {
    border-bottom: 2px solid #333;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

ul {
    list-style-type: disc;
    margin-left: 20px;
}