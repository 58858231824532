.page-title {
    margin-bottom: 20px; /* Space below the title */
    text-align: center;
}

.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input-group {
    margin-bottom: 15px;
}

.contact-form input::-webkit-input-placeholder,
.contact-form textarea::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.7);
}

/* For Internet Explorer and Microsoft Edge */
.contact-form input:-ms-input-placeholder,
.contact-form textarea:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.7);
}

.contact-form-wrapper {
    width: 100%;
    max-width: 500px;
    background: rgba(255, 255, 255, 0.1); /* Transparent background */
    padding: 20px;
    border: 1px solid rgba(255, 255, 255, 1.0); /* Slight border for definition */
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.contact-form input,
.contact-form textarea {
    background: rgba(255, 255, 255, 0.2); /* Transparent background for inputs */
    width: 100%;
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, 1.0); /* Slight border for inputs */
    border-radius: 5px;
    font-size: 16px;
    margin-bottom: 10px;
    color: #ffffff; /* White text color for better visibility on transparent background */
}

.contact-form button {
    background-color: #007BFF;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.contact-form button:hover {
    background-color: #0056b3;
}

.contact-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.contact-details p {
    margin-bottom: 10px;
}

.contact-details i {
    margin-right: 10px;
}

.social-links a {
    margin: 0 10px;
    font-size: 24px;
    color: #fff;
    transition: color 0.3s;
}

.social-links a:hover {
    color: #007BFF;
}