body,
#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.container {
    flex-grow: 1;
}

.footer {
    flex-shrink: 0;
}

.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center; /* This will center content vertically */
    padding: 2rem; /* This adds padding around the content */

    color: white;
    position: relative; /* Ensure this is positioned relative to the slideshow */
    z-index: 3; /* Set a higher z-index so it appears on top of the slideshow */
}

.home-content {
    text-align: center;
    color: white;
}