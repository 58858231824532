@keyframes kenburns {
    0% {
        transform: scale(1) translate(0);
    }

    100% {
        transform: scale(1.1) translate(-2.5%, -5%);
    }
}

.slideshow-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; /* Ensure slideshow is behind content */
    overflow: hidden; /* Hide overflow */
}

.slide {
    width: 100%;
    height: 100%;
    position: relative;
    animation: kenburns 10s infinite alternate;
}

.slide-image {
    width: 100%;
    height: 100%;
    object-fit: cover
}

.slide-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Adjust transparency as needed */
}